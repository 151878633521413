import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { useNavigate } from "react-router-dom";
// import axios from "../api/axiosConfig";
import {
  Container,
  Typography,
  Card,
  CssBaseline,
  CardContent,
  Box,
  Avatar,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import AlertMessage from "../components/AlertMessage";

import useAuth from "../hooks/useAuth";
import { loginUser } from "../api/users";

const Login = () => {
  const { setIsAuthenticated, setAccessToken, setLocation  } = useAuth();

  const navigate = useNavigate();

  // Value and handlers for toggle visibility effect in password fields
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // Values and handlers for authentication
  const [email, setEmail] = useState("");
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [signInAttempted, setSignInAttempted] = useState(false);
  const [signInFailed, setSignInFailed] = useState(false);
  const loginMutation = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.length !== 0 && data?.access_token !== 0) {
       // console.log(data)
        setLocation(data.location)
        setAccessToken(data?.access_token);
        setIsAuthenticated(true);
        console.log(data);
        if(data.location==="SLOVAKIA")
          navigate("/dashboardslov");
        else
          navigate("/dashboard");
      }
    },
    onError: (data) => {
      setSignInFailed(true);
      setErrorMessage(data.message);
    },
  });
  const { mutate, isLoading } = loginMutation;

  // Check validity of email field after the first Sign In attempt (user has pressed Sign In button)
  useEffect(() => {
    if (signInAttempted === true) checkEmail();
  }, [email, signInAttempted]);

  // Check validity of password field after the first Sign In attempt (user has pressed Sign In button)
  useEffect(() => {
    if (signInAttempted === true) checkPassword();
  }, [password, signInAttempted]);

  // Function for email field validity
  const checkEmail = () => {
    email === "" ? setEmailEmpty(true) : setEmailEmpty(false);
    // emailRegexValidate(email) ? setEmailInvalid(false) : setEmailInvalid(true)
  };

  // Function for password field validity
  const checkPassword = () => {
    password === "" ? setPasswordEmpty(true) : setPasswordEmpty(false);
  };

  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const checkForm = (e) => {
    e.preventDefault();

    // Initialize this value in order to enable field checks on change
    setSignInAttempted(true);

    checkEmail();
    checkPassword();

    // If all fields are valid, proceed to Sign In
    if (email !== "" && password !== "") {
      handleSignIn(email, password);
    }
  };

  const handleSignIn = async (email, password) => {
    mutate({ email, password });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "90vh",
      }}
    >
      <Card
        sx={{
          width: "100%",
        }}
      >
        <CardContent>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{
                m: 2,
                bgcolor: "secondary.main",
              }}
            >
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              FOODRUS
            </Typography>
            <form
              // sx={classes.form}
              noValidate
            >
              <TextField
                onChange={(e) => handleChangeEmail(e)}
                value={email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                type="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                helperText={
                  emailEmpty && email === "" && "This field is required."
                }
                // : emailInvalid ? "This field should be in the form email@email.com" : ''

                // error={(emailEmpty && email === '') || emailInvalid}
                error={emailEmpty && email === ""}
              />
              <TextField
                onChange={(e) => handleChangePassword(e)}
                label="Password"
                variant="outlined"
                fullWidth
                required
                helperText={passwordEmpty && "This field is required."}
                error={passwordEmpty}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {signInFailed && <AlertMessage message={errorMessage} />}
              {/* <AlertMessage message="The credentials you provided do not match. Please try again." /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={(e) => checkForm(e)}
                disabled={isLoading === true}
              >
                Sign In
              </Button>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
