import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import Header from "../components/Header";
import CustomizedTables from "../components/CustomizedTables";
import Spinner from "../components/Spinner";
import AlertMessage from "../components/AlertMessage";
import { useErrorHandler } from "../hooks/useErrorHandler";

import { getUnifiedSalesData } from "../api/products";
const columns = [
  { id: 1, headerName: "Date", name: "date" },
  { id: 2, headerName: "Product Name", name: "productType" },
  { id: 3, headerName: "Product ID", name: "product_id" },
  { id: 4, headerName: "Sold  Units", name: "units_sold" },
  {
    id: 5,
    headerName: "Stock",
    name: "stock",
  },
  {
    id: 6,
    headerName: "Units discount",
    name: "units_discount",
  },
];

const History = () => {
  const theme = useTheme();
  //const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { errorMessage, handleError } = useErrorHandler();

  const {
    data: historicalData,
    error,
    isLoading,
    refetch,
  } = useQuery(["historicalData"], getUnifiedSalesData, {
    staleTime: Infinity,
    onError: (error) => {
      handleError(error);
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <AlertMessage message="Failed to fetch data. Please try again." />;
  }

  return (
    <Box m="20px">
      <Header title="HISTORICAL DATA" />
      {errorMessage && <AlertMessage message={errorMessage} />}
      <Grid container spacing={2} justifyContent="center">
        {/* Date Picker FORM*/}
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <CustomizedTables
            data={historicalData}
            tableName="Historical Data"
            columnNames={columns}
            lastNextDay={"Last"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default History;
