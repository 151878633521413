import axios from "./axiosConfig";

import { APP_ENDPOINT_PROD } from "../utils/Constant";

export const getPredictionDays = async (selectedCategory) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s1.1/predictionDays?productId=${selectedCategory}`
    );
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }
    throw new Error("Failed to fetch data");
  }
};
