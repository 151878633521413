import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Spinner = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.1)",
      }}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
