import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DownloadIcon from "@mui/icons-material/Download";

import Header from "../components/Header";
import CustomizedTables from "../components/CustomizedTables";
import Spinner from "../components/Spinner";
import AlertMessage from "../components/AlertMessage";
import LineChartOne from "../components/charts/LineChartOne";
import Dropdown from "../components/filter/Dropdown";
import DateSelection from "../components/filter/DateSelection";
import { useErrorHandler } from "../hooks/useErrorHandler";

import { generateNextDateBasedOnRange } from "../utils/dateUtils";
import { exportAsCSV } from "../utils/ExportUtils";
import { getForecastByDateRange } from "../api/forecast";
import { getProductCategory } from "../api/products";
import { getPredictionDays } from "../api/modelIA";

const columns = [
  { id: 1, headerName: "Product Name", name: "product_type" },
  { id: 2, headerName: "Product ID", name: "product_id" },
  { id: 3, headerName: "Date", name: "date" },
  { id: 4, headerName: "Forecast - This year", name: "current_year_forecast" },
  { id: 5, headerName: "Forecast - Last year", name: "previous_year_forecast" },
  { id: 6, headerName: "Company", name: "company" },
  // { id: 7, headerName: "Energy", name: "energy_kwh_unit" },
  // { id: 8, headerName: "Flour", name: "flour_g_unit" },
  // { id: 9, headerName: "Water", name: "water_ml_unit" },
];

const lines = [
  {
    name: "current_year_forecast",
    columnName: "Forecast - This year",
    color: "#8884d8",
  },
  {
    name: "previous_year_forecast",
    columnName: "Forecast - Last year",
    color: "#82ca9d",
  },
];

function Dashboard() {
  const { errorMessage, handleError } = useErrorHandler();
  const [selectedCategory, setSelectedCategory] = useState();
  const [productCategory, setProductCategory] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("1");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [tableHeight, setTableHeight] = useState();

  // all the data
  const [forecastData, setForecastData] = useState([]);
  const [predictionDays, setPredictionDays] = useState({});
  const crRef = useRef();

  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // useEffect(() => {
  //   if (crRef.current) {
  //     const chartContainerHeight = crRef.current.clientHeight;
  //     setTableHeight(chartContainerHeight);
  //   }
  // }, [crRef.current]);

  const { error: errorCategory, isLoading: isLoadingCategory } = useQuery(
    ["productCategory"],
    getProductCategory,
    {
      onSuccess: (data) => {
        setSelectedCategory(data?.[0]?.id);
        setProductCategory(data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const { error: errorForecast, isLoading: isLoadingForecast } = useQuery(
    ["predionData", startDate, endDate, selectedCategory],
    () => getForecastByDateRange(startDate, endDate, selectedCategory),
    {
      onSuccess: (data) => {
     //   console.log(data)
        setForecastData(data);
      },
      enabled:
        !!selectedCategory || Object.entries(predictionDays).length !== 0,
      onError: (error) => {
        handleError(error);
      },
    }
  );
  const { error: errorPredictionDays, isLoading: isLoadingPredictionDays } =
    useQuery(
      ["predionDays", selectedCategory],
      () => getPredictionDays(selectedCategory),
      {
        onSuccess: (data) => {
          setPredictionDays(data.days_to_predict);
          setSelectedDateRange(String(data.days_to_predict));
        },
        enabled: !!selectedCategory,
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const handleDateRange = (selectedDateRange) => {
    setSelectedDateRange(selectedDateRange);
  };

  const handleChangeCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
  };
  const handleEndDate = (endDate) => {
    setEndDate(endDate);
  };
  const generateDateRange = (dateRange) => {
    const { from, to } = generateNextDateBasedOnRange(dateRange);
    setStartDate(from);
    setEndDate(to);
  };

  useEffect(() => {
    generateDateRange(selectedDateRange);
  }, [selectedDateRange, predictionDays]);

  if (isLoadingCategory || isLoadingPredictionDays || isLoadingForecast) {
    return <Spinner />;
  }

  return (
    <Box m="20px">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
        // style={{
        //   display: "flex",
        //   flexDirection: isSmallScreen ? "column" : "row",
        // }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header title="DASHBOARD" />
          {errorMessage && <AlertMessage message={errorMessage} />}
        </Grid>
        {/* First row - left */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justifyContent="left"
          >
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedCategory}
                onSelect={handleChangeCategory}
                data={productCategory}
                title="Product category"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedDateRange}
                onSelect={handleDateRange}
                data={[
                  { id: 1, name: "1", label: "Next day" },
                  { id: 2, name: "5", label: "Next 5 days" },
                  {
                    id: 3,
                    name: "7",
                    label: "Next 7 days",
                  },
                  { id: 4, name: "30", label: "Next 30 days" },
                  { id: 5, name: "customRange", label: "Next Custum Range" },
                ]}
                title="Range"
                predictionDays={predictionDays}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleStartDate}
                dateSelected={startDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Next"}
                label="From"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleEndDate}
                dateSelected={endDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Next"}
                label="To"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* First row - right */}
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              exportAsCSV(forecastData);
            }}
            disabled={forecastData.length === 0}
            variant="contained"
            color="success"
            startIcon={<DownloadIcon />}
          >
            DOWNLOAD REPORT
          </Button>
        </Grid>
        {/* Third row - left */}

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          ref={crRef}
          sx={{ height: 400 }}
        >
          <LineChartOne
            chartData={forecastData}
            caption="Product demand forecasting"
            // timestampKey="timestamp"
            lines={lines}
          />
        </Grid>
        {/* Third row - right  */}
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: 400 }}>
          <CustomizedTables
            data={forecastData}
            tableName="Product demand forecasting"
            columnNames={columns}
            lastNextDay={"Next"}
            table_id={"table1"}
            withSearch={true}
            withFilter={false}
            withEdit={false}
            withDelete={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
