import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
//import { fabClasses } from "@mui/material";

const Dropdown = ({
  selectedCategory,
  onSelect,
  data,
  title,
  predictionDays,
}) => {
  const handleChangeCategory = (event) => {
    event.preventDefault();
    onSelect(event.target.value);
  };
  return (
    <FormControl variant="standard" sx={{ mt: 1, width: "100%" }}>
      <InputLabel id="input-lable-select-key">{title}</InputLabel>
      <Select
        labelId="select-key-label"
        id="select-key"
        value={selectedCategory || ""}
        label="Key"
        onChange={handleChangeCategory}
      >
        {data?.map((item) => {
          return (
            <MenuItem
              key={item.id}
              value={title !== "Range" ? item.id : item.name}
              disabled={
                item?.label?.includes("Next")
                  ? Number(predictionDays) < Number(item.name) ||
                    (Number(predictionDays) < 30 && item.name === "customRange")
                  : false
              }
            >
              {title !== "Range" ? item.product_type : item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
