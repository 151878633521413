import { useMediaQuery, useTheme } from "@mui/material";

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { styled } from "styled-components";

// Chart containing filtered data for the passed key/product type
const Chart = ({
  chartData,
  chartRef,
  timeseriesKey,
  firstLineName,
  secondLineName,
  thirdLineName,
}) => {
  const theme = useTheme();
  const isSmallScreenheight = useMediaQuery(theme.breakpoints.down("sm"));
  const heightPercentage = isSmallScreenheight ? "90%" : "100%";

  const CustomLegend = ({
    firstLineName,
    secondLineName,
    thirdLineName,
    caption,
    firstStrokeColor,
    secondStrokeColor,
  }) => {
    return (
      <div style={{ textAlign: "center" }}>
        <ul
          style={{
            listStyleType: "square",
            padding: 0,
            display: "inline-block",
          }}
        >
          <li style={{ display: "inline-block", marginRight: "20px" }}>
            <span style={{ color: "#8884d8", fontSize: "1.2em" }}>
              {firstLineName}
            </span>
          </li>
          <li style={{ display: "inline-block", marginRight: "20px" }}>
            <span style={{ color: "#82ca9d", fontSize: "1.2em" }}>
              {secondLineName}
            </span>
          </li>
          <li style={{ display: "inline-block" }}>
            <span style={{ color: "#f00", fontSize: "1.2em" }}>
              {thirdLineName}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const linePayload = payload.filter((entry) => entry.stroke !== "false");
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {linePayload.map((entry, index) => (
            <p key={`line-${index}`} className="line-info">
              <span className="line-name">{entry.name}: </span>
              <span className="line-value">{entry.value}</span>
            </p>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <ChartDiv
        class_name="chart_div"
        ref={chartRef}
        style={{ height: heightPercentage }}
      >
        <div class_name="title">
          <h3 className="p-2 mb-0">Daily Sales and Prediciton analysis</h3>
        </div>
        <ResponsiveContainer width="100%">
          <ComposedChart
            // width={500}
            width="100%"
            // height={400}
            data={chartData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 50,
            }}
          >
            {/* Styles for the Line and Area */}
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorRd" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff3f00" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ff3f00" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              strokeDasharray="1"
              className="chart_grid"
              vertical={false}
            />
            <XAxis dataKey={"date"} />
            <YAxis />
            {/* TODO: Fix tooltip showing both line and area keys */}
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={
                <CustomLegend
                  firstLineName={firstLineName}
                  secondLineName={secondLineName}
                  thirdLineName={thirdLineName}
                />
              }
            />

            <Line
              type="monotone"
              dataKey="units_forecasted"
              stackId="1"
              stroke="#027F89"
              fillOpacity={1}
              fill="url(#colorUv)"
            />

            <Line
              type="monotone"
              dataKey="units_sold"
              stackId="1"
              stroke="#82ca9d"
              fillOpacity={1}
              fill="url(#colorPv)"
            />
            <Line
              type="monotone"
              dataKey="prediction_error"
              stackId="1"
              stroke="#ff7f58"
              fillOpacity={1}
              fill="url(#colorRd)"
            />
            <Area
              type="monotone"
              dataKey="units_forecasted"
              stroke="false"
              fill="url(#colorUv)"
            />

            <Area
              type="monotone"
              dataKey="units_sold"
              stroke="false"
              fill="url(#colorPv)"
            />
            <Area
              type="monotone"
              dataKey="prediction_error"
              stroke="false"
              fill="url(#colorRd)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartDiv>
    </>
  );
};

const ChartDiv = styled.div`
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 0.5rem;
  align-self: center;

  .title {
    margin-bottom: 1rem;
  }

  h5 {
    font-weight: 700;
  }

  .chart_grid {
    stroke: rgb(228, 225, 225);
  }

  @media screen and (max-width: 500px) {
    .recharts-wrapper {
      max-width: 100%;
      height: auto;
    }
  }
`;

export default Chart;
