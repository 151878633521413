import axios from "./axiosConfig";

import { APP_ENDPOINT_PROD } from "../utils/Constant";

import { formatDate } from "../utils/dateUtils";

export const getForecastByDateRange = async (
  startDate,
  endDate,
  selectedCategory
) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s1.1/forecastData?date_gte=${formatDate(
        startDate
      )}&date_lte=${formatDate(endDate)}&productId=${selectedCategory}`
    );
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 

    throw new Error("Failed to fetch data");
  }
};

export const getForecastAnalysByDateRange = async (
  startDate,
  endDate,
  selectedCategory
) => {
  try {
    const res = await axios.get(
      `${APP_ENDPOINT_PROD}/s1.1/forecastAnalysisData?date_gte=${formatDate(
        startDate
      )}&date_lte=${formatDate(endDate)}&productId=${selectedCategory}`
    );
    return res.data;
  } catch (error) {
    if(error.response?.data){
      throw new Error(error.response?.data?.message);
    } 
    throw new Error("Failed to fetch data");
  }
};
