import axios from "./axiosConfig";

import { APP_ENDPOINT_PROD } from "../utils/Constant";

export const getProducts = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s1.1/products`);
    return res.data;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const getProductCategory = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s1.1/products`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }

    throw new Error("Failed to fetch data");
  }
};

export const getUnifiedSalesData = async () => {
  try {
    const res = await axios.get(`${APP_ENDPOINT_PROD}/s1.1/unifiedSalesData`);
    return res.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data?.message);
    }

    throw new Error("Failed to fetch data");
  }
};

// export const getProducts = async () => {
//   try {
//     const res = await axios.get(`${APP_ENDPOINT}/historicalData`);
//     return res.data;
//   } catch (error) {
//     throw new Error("Failed to fetch data");
//   }
// };
