import React, { useEffect,  useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import DownloadIcon from "@mui/icons-material/Download";

import Header from "../components/Header";
import Chart from "../components/charts/Chart";
import AlertMessage from "../components/AlertMessage";
import Spinner from "../components/Spinner";
import CustomizedTables from "../components/CustomizedTables";
import Dropdown from "../components/filter/Dropdown";
import DateSelection from "../components/filter/DateSelection";
import { useErrorHandler } from "../hooks/useErrorHandler";

import { getForecastAnalysByDateRange } from "../api/forecast";
import { getProductCategory } from "../api/products";
import { generatePastDateRange } from "../utils/dateUtils";
import { exportAsCSV } from "../utils/ExportUtils";
// import LineChartOne from "../components/charts/LineChartOne";

const columns = [
  { id: 1, headerName: "Product Name", name: "product_type" },
  { id: 2, headerName: "Product ID", name: "product_id" },
  { id: 3, headerName: "Date", name: "date" },
  { id: 4, headerName: "Units Forecasted", name: "units_forecasted" },
  { id: 5, headerName: "Units Sold", name: "units_sold" },
  { id: 6, headerName: "Prediction Error", name: "prediction_error" },
  { id: 7, headerName: "Upper bound", name: "upper_bound" },
  { id: 8, headerName: "Lower bound", name: "lower_bound" },
  { id: 9, headerName: "Company", name: "company" },
];

const Statistics = () => {
  const { errorMessage, handleError } = useErrorHandler();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [productCategory, setProductCategory] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState("7");

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [forecastAnalysData, setForecastAnalysData] = useState([]);
  //const chartRef = useRef(null);

  const { error: errorForecastAnalys, isLoading: isLoadingForecastAnalys } =
    useQuery(
      ["forecastAnalysData", { startDate, endDate, selectedCategory }],
      () => getForecastAnalysByDateRange(startDate, endDate, selectedCategory),
      {
        onSuccess: (data) => {
          //console.log(data)
          setForecastAnalysData(data);
        },
        enabled: !!selectedCategory,
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const { error: errorCategory, isLoading: isLoadingCategory } = useQuery(
    ["productCategory"],
    getProductCategory,
    {
      onSuccess: (data) => {
        setProductCategory(data);
        setSelectedCategory(data[0]?.id);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleDateRange = (selectedDateRange) => {
    setSelectedDateRange(selectedDateRange);
  };

  const handleChangeCategory = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const handleStartDate = (startDate) => {
    setStartDate(startDate);
  };
  const handleEndDate = (endDate) => {
    setEndDate(endDate);
  };
  const generateDateRange = (dateRange) => {
    const { from, to } = generatePastDateRange(dateRange);
    setStartDate(from);
    setEndDate(to);
  };

  useEffect(() => {
    generateDateRange(selectedDateRange);
  }, [selectedDateRange]);

  // const lines = [
  //   {
  //     name: "units_forecasted",
  //     columnName: "Units Forecasted",
  //     color: "#8884d8",
  //   },
  //   {
  //     name: "sold_units",
  //     columnName: "Units Sold",
  //     color: "#82ca9d",
  //   },
  //   {
  //     name: "prediction_error",
  //     columnName: "Prediction Error",
  //     color: "#de5b3f",
  //   },
  // ];

  if (isLoadingCategory || isLoadingForecastAnalys) {
    return <Spinner />;
  }

  return (
    <Box m="20px">
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Header title="STATISTICS" />
          {errorMessage && <AlertMessage message={errorMessage} />}
        </Grid>
        {/* Date Picker FORM*/}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid
            container
            spacing={2}
            direction="row"
            alignItems="left"
            justifyContent="left"
          >
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedCategory}
                onSelect={handleChangeCategory}
                data={productCategory}
                title="Product category"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <Dropdown
                selectedCategory={selectedDateRange}
                onSelect={handleDateRange}
                data={[
                  { id: 1, name: "1", label: "Last day" },
                  { id: 2, name: "7", label: "Last 7 days" },
                  { id: 3, name: "30", label: "Last 30 days" },
                  { id: 4, name: "customRange", label: "Custum Range" },
                ]}
                title="Range"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleStartDate}
                dateSelected={startDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Last"}
                label="From"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <DateSelection
                onChangeDate={handleEndDate}
                dateSelected={endDate}
                selectedDateRange={selectedDateRange}
                lastNextDay={"Last"}
                label="To"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              exportAsCSV(forecastAnalysData);
            }}
            variant="contained"
            color="success"
            startIcon={<DownloadIcon />}
            disabled={forecastAnalysData.length === 0}
          >
            DOWNLOAD REPORT
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          // sx={{ overflowX: "auto", height: 450 }}
          sx={{ height: 400 }}
        >
          <Chart
            chartData={forecastAnalysData}
            caption="Daily Sales and Prediciton analysis"
            // chartRef={chartRef}
            // lines={lines}
            firstLineName="Units Forecasted"
            secondLineName="Units Sold"
            thirdLineName="Prediction Error"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ height: 400 }}>
          <CustomizedTables
            data={forecastAnalysData}
            tableName="Daily Sales and Prediciton analysis"
            columnNames={columns}
            lastNextDay={"Last"}
            withEdit={false}
            withDelete={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Statistics;
