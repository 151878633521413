import { useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { styled } from "styled-components";

const CustomLegend = (props) => {
  return (
    <div style={{ textAlign: "center", maxWidth: "100%", overflow: "auto" }}>
      <ul
        style={{
          listStyleType: "square",
          padding: 0,
          display: "inline-block",
        }}
      >
        {props.lines.map((line, index) => (
          <li
            key={index}
            style={{ display: "inline-block", marginRight: "20px" }}
          >
            <span style={{ color: line.color, fontSize: "1.2em" }}>
              {line.columnName}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    //console.log(payload)
    return (
      <div className="custom-tooltip" style={{ maxWidth: "100%" }}>
        <p className="label">{`${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`line-${index}`} className="line-info">
            <span className="line-name">{entry.name}: </span>
            {/* {entry.unit} */}
            { entry.unit ? (
              <span className="line-value">{entry.value +" "+entry.unit}</span>
            ) : (
              <span className="line-value">{entry.value}</span>
            )}
          </p>
        ))}
      </div>
    );
  }
};

export default function LineChartOne({
  chartData,
  caption,
  timestampKey,
  unit,
  type = "monotone",
  lines,
  transformData,
}) {
  const transformedData = transformData ? transformData(chartData) : chartData;
  const chartRef = useRef(null);

  const theme = useTheme();
  const isSmallScreenheight = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreenheight = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreenheight = useMediaQuery(theme.breakpoints.down("lg"));
  const heightPercentage =
    isSmallScreenheight || isMediumScreenheight || isLargeScreenheight
      ? "90%"
      : "100%";

  return (
    <ChartDiv
      class_name="chart_div"
      ref={chartRef}
      style={{ height: heightPercentage }}
    >
      <div class_name="title">
        <h2 className="p-2 mb-0">{caption}</h2>
      </div>
      {/* <ResponsiveContainer width="100%" height={400}> */}
      <ResponsiveContainer>
        <LineChart
          // width="100%"
          // height="100%"
          data={transformedData}
          margin={{
            top: 5,
            right: 30,
            left: 10,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={"date"} padding={{ left: 0, right: 30 }} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="bottom" // This aligns the legend at the bottom
            wrapperStyle={{
              paddingBottom: "20px", // Adds padding at the bottom of the legend
            }}
            content={<CustomLegend lines={lines} />}
          />
          {lines.map((line, index) => (
            <Line
              key={index}
              type={type}
              dataKey={line.name}
              stroke={line.color}
              name={line.name}
              unit={unit}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </ChartDiv>
  );
}

const ChartDiv = styled.div`
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 0.5rem;
  align-self: center;
  width: 100%; // Ensure full width
  max-width: 100%; // Prevent any larger width than the parent container

  .title {
    margin-bottom: 1rem;
  }

  h5 {
    font-weight: 700;
  }

  .chart_grid {
    stroke: rgb(228, 225, 225);
  }

  @media screen and (max-width: 500px) {
    .recharts-wrapper {
      max-width: 100%;
      height: auto;
    }
  }
`;
