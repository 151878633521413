import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material/";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import AuthProvider from "./context/AuthContext";
import Dashboard from "./pages/Dashboard";
import DashboardNotFilter from "./pages/DashboardNotFilter";
import DashboardNotFilterSlov from "./pages/DashboardNotFilterSlov";
import DashboardSlov from "./pages/DashboardSlov";
import Login from "./pages/Login";
import PrivateRoute from "./components/auth/PrivateRoute";
import Topbar from "./components/Topbar";
import Statistics from "./pages/Statistics";
import AdminPage from "./pages/AdminPage";
import History from "./pages/History";
import theme from "./theme";
import { CollapsibleProSidebarProvider } from "./context/SidebarContext";

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CssBaseline />
          <Router>
            <CollapsibleProSidebarProvider>
              {/* <div className="app"> */}
              <div className="content">
                <main>
                  <Topbar />
                  <Routes>
                    <Route
                      path="/dashboardslov"
                      element={
                        <PrivateRoute>
                          <DashboardSlov/>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboardnofilterslov"
                      element={
                        <PrivateRoute>
                          <DashboardNotFilterSlov/>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/dashboardnofilter"
                      element={
                        <PrivateRoute>
                          <DashboardNotFilter />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/statistics"
                      element={
                        <PrivateRoute>
                          <Statistics />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/history"
                      element={
                        <PrivateRoute>
                          <History />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admin"
                      element={
                        <PrivateRoute>
                          <AdminPage />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </main>
              </div>
            </CollapsibleProSidebarProvider>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
