import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

import { Box, useTheme, IconButton, Tooltip, Typography } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
// import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";

import useAuth from "../hooks/useAuth";
import useSidebar from "../hooks/useSidebar";

import logoImage from "../assets/logo_simple.png";

const Topbar = () => {
  //const [isVisible, setVisible] = useState(false);
  const { isAuthenticated, locationU, logout } = useAuth();
  const { openSidebar } = useSidebar();
  // const { collapseSidebar, toggleSidebar, collapsed, broken, open } =
  //   useProSidebar();

  const theme = useTheme();
  const colors = theme.palette;
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOut = () => {
    navigate("/login");
    logout();
  };
  const handleAdminClick = () => {
    openSidebar();
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={1}
      bgcolor={colors.primary.main}
    >
      <Box display="flex" flexDirection="row">
        <Box>
          <img src={logoImage} alt="foodrus logo" height={30} />
        </Box>

        <Box marginTop={1}>
          <Typography color="white" fontSize={15} paddingLeft={2}>
            PRODUCT DEMAND
          </Typography>
        </Box>
      </Box>
      {/* icon</Box>s */}
      {isAuthenticated && (
        <Box display="flex">
          {locationU === "SLOVAKIA" ?(
            <Tooltip
            placement="bottom"
            title={<Typography fontSize={14}>Dashboard</Typography>}
          >
            <IconButton
              component={Link}
              to="/dashboardslov"
              style={
                location.pathname === "/dashboardslov"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <DashboardOutlinedIcon />
            </IconButton>
          </Tooltip>
          ):(
            <Tooltip
            placement="bottom"
            title={<Typography fontSize={14}>Dashboard</Typography>}
          >
            <IconButton
              component={Link}
              to="/dashboard"
              style={
                location.pathname === "/dashboard"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <DashboardOutlinedIcon />
            </IconButton>
          </Tooltip>
          )}
          {locationU === "SLOVAKIA" ?(
             <Tooltip
             placement="bottom"
             title={<Typography fontSize={14}>Forecast Data History</Typography>}
           >
             <IconButton
               component={Link}
               to="/dashboardnofilterslov"
               style={
                 location.pathname === "/dashboardnofilterslov"
                   ? { color: colors.secondary.main }
                   : { color: colors.lightGrey.main }
               }
             >
               <DataThresholdingOutlinedIcon />
             </IconButton>
           </Tooltip>
          ):(
            <Tooltip
            placement="bottom"
            title={<Typography fontSize={14}>Forecast Data History</Typography>}
          >
            <IconButton
              component={Link}
              to="/dashboardnofilter"
              style={
                location.pathname === "/dashboardnofilter"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <DataThresholdingOutlinedIcon />
            </IconButton>
          </Tooltip>
          )}
         
          <Tooltip
            title={<Typography fontSize={14}>Statistics</Typography>}
            placement="bottom"
            sx={{ fontSize: 30 }}
          >
            <IconButton
              component={Link}
              to="/statistics"
              style={
                location.pathname === "/statistics"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <BarChartOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={<Typography fontSize={14}>History</Typography>}
            placement="bottom"
          >
            <IconButton
              component={Link}
              to="/history"
              style={
                location.pathname === "/history"
                  ? { color: colors.secondary.main }
                  : { color: colors.lightGrey.main }
              }
            >
              <HistoryOutlinedIcon />
            </IconButton>
          </Tooltip>
          {/* {locationU === "SLOVAKIA" && (
            <Tooltip
              title={<Typography fontSize={14}>DataThresholding</Typography>}
              placement="bottom"
            >
              <IconButton
                component={Link}
                to="/history"
                style={
                  location.pathname === "/history"
                    ? { color: colors.secondary.main }
                    : { color: colors.lightGrey.main }
                }
              >
                <DataThresholdingOutlinedIcon />
              </IconButton>
            </Tooltip>
          )} */}
          {/* {isVisible && (
            <Tooltip
              title={<Typography fontSize={14}>Admin</Typography>}
              placement="bottom"
            >
              <IconButton onClick={handleAdminClick}>
                <AdminPanelSettingsOutlinedIcon
                  style={{ color: colors.lightGrey.main }}
                />
              </IconButton>
            </Tooltip>
          )} */}
          <Tooltip
            title={<Typography fontSize={14}>Logout</Typography>}
            placement="bottom"
          >
            <IconButton
              onClick={handleLogOut}
              style={{ color: colors.lightGrey.main }}
            >
              <ExitToAppOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Topbar;
